interface TextForLanguage {
  pageTitle: string
  errorMessage: string
  text: string
  buttonText: string
}

const pageTitle = "404"

const germanText: TextForLanguage = {
  errorMessage: "Es sieht so aus, als ob Du vom Weg abgekommen bist",
  text: "Nimm einen tiefen Atemzug",
  buttonText: "Gehe zurück zum Anfang",
  pageTitle,
}

const englishText: TextForLanguage = {
  errorMessage: "It looks like you've lost your way",
  text: "Take a deep breath",
  buttonText: "Go back home",
  pageTitle,
}

const textForLanguages = {
  german: germanText,
  english: englishText,
}

export default textForLanguages
