import React, { useContext } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
  ButtonProps,
} from "@material-ui/core"

import { ColorSchemeContext } from "../contexts/ColorScheme"

const styles = ({
  palette: {
    text: { primary },
  },
  breakpoints: { up },
}: Theme) => {
  const lg = up("lg")
  return createStyles({
    button: {
      borderRadius: "0.5625rem",
      fontSize: "1.125rem",
      color: primary,
      lineHeight: "2.125rem",
      padding: "0.875rem 2.5rem",
      [lg]: {
        padding: "1.3125rem 4.625rem",
      },
    },
    light: {
      background:
        "transparent linear-gradient(90deg, rgba(216, 226, 230, 0.5) 0%, rgba(201, 213, 224, 0.5) 100%) 0% 0% no-repeat padding-box",
    },
    dark: {
      background:
        "transparent linear-gradient(90deg, rgba(87, 91, 93, 0.5) 0%, rgba(71, 89, 106, 0.5) 100%) 0% 0% no-repeat padding-box",
    },
  })
}

export interface SecondaryButtonProps
  extends WithStyles<typeof styles>,
    Omit<ButtonProps, "classes"> {}

const SecondaryButton = ({
  classes,
  className: extraClassName = "",
  ...other
}: SecondaryButtonProps) => {
  const { colorScheme } = useContext(ColorSchemeContext)!

  const getToneClassName = (): string => {
    if (colorScheme === "morning" || colorScheme === "day") {
      return classes.light
    }
    return classes.dark
  }

  const getClassName = (): string => {
    const toneClassName: string = getToneClassName()
    return `${classes.button} ${toneClassName} ${extraClassName}`
  }

  const className: string = getClassName()

  return <Button className={className} variant="contained" {...other} />
}

export default withStyles(styles)(SecondaryButton)
