import { withStyles, createStyles, Theme } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

import PageContainer from "./PageContainer"
import { navDimensions, navWithLogoDimensions } from "../constants/heights"

export const getMinHeight = (
  breakpoint: Breakpoint,
  marginBottom: string = navDimensions.marginBottom
): string => {
  const height: string =
    breakpoint === "xs"
      ? navDimensions.height
      : navDimensions[breakpoint].height
  return `calc(100vh - ${height} - ${marginBottom})`
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up("md")
  const lg = up("lg")
  return createStyles({
    root: {
      minHeight: getMinHeight("xs"),
      [md]: {
        minHeight: getMinHeight("md"),
      },
      [lg]: {
        minHeight: getMinHeight("lg", navWithLogoDimensions.lg.marginBottom),
      },
    },
  })
}

export default withStyles(styles)(PageContainer)
