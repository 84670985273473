import React from "react"
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"
import NavWithLogo from "../components/Layout/NavWithLogo"
import { useLanguageText } from "../utils/language"
import textForLanguages from "../content/pages/404"
import SecondaryButton from "../components/SecondaryButton"
import links from "../constants/links"
import FullHeightPageContainer from "../components/FullHeightPageContainer"

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up("lg")
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    title: {
      fontSize: "5rem",
      [lg]: {
        fontSize: "14.3125rem",
        lineHeight: "17.1875rem",
        fontWeight: 300,
      },
    },
    errorMessage: {
      [lg]: {
        fontSize: "2.25rem",
        lineHeight: "3.4375rem",
        marginBottom: "0.9375rem",
      },
    },
    text: {
      fontSize: "1.5rem",
      lineHeight: "3.4375rem",
      marginBottom: "0.125rem",
      fontWeight: 400,
      [lg]: {
        marginBottom: "3.3125rem",
      },
    },
    button: {
      [lg]: {
        padding: "1.625rem 2.4375rem",
        fontSize: "1.25rem",
        lineHeight: "2rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const NotFound = ({ classes }: Props) => {
  const { pageTitle, errorMessage, text, buttonText } = useLanguageText<
    typeof textForLanguages
  >(textForLanguages)
  return (
    <Layout footer={null} nav={<NavWithLogo />}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <FullHeightPageContainer className={classes.root}>
        <Typography variant="h1" className={classes.title}>
          {pageTitle}
        </Typography>
        <Typography variant="h2" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
        <Typography className={classes.text}>{text}</Typography>
        <SecondaryButton href={links.home} classes={{ button: classes.button }}>
          {buttonText}
        </SecondaryButton>
      </FullHeightPageContainer>
    </Layout>
  )
}

export default withStyles(styles)(NotFound)
